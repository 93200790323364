import React from "react";

function TouristSpot(props) {
    return (
        <div className="tourist-spots-width">
            <div className="d-flex flex-row justify-content-center">
                <img src={props.imgSrc} className="tourist-spots-thumbnail-img object-fit-cover slide-up" alt="tourist spots"></img>
            </div>
            <p className="larger-letters text-center ps-0 mb-0 mt-2 slide-up">{props.name}</p>
            <p className="large-letters text-center ps-0 mb-0 slide-up">{props.address}</p>
        </div>
    )
}

export default TouristSpot;