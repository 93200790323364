import React from 'react';

function Entourage() {
    return(
        <div id="entourage" className="section">
            <div className="px-4 py-5 mt-3 text-center">
                <p className="title-letters-cursive-2 cursive-font slide-up">Entourage</p>
                <p className="mb-0 slide-right fw-bold larger-letters">OFFICIATING PASTOR</p>
                <div>
                    <p className="slide-right large-letters fst-italic">Rev. Jesus L. Reguero</p>
                </div>
                
                <div className="flex-container">
                    <div className="flex-item-container">
                        <p className="mb-0 slide-right text-end fw-bold larger-letters">GROOM'S PARENTS</p>
                        <div>
                            <p className="slide-right text-end mb-0 large-letters fst-italic">Mr. Elvis S. Reganot</p>
                            <p className="slide-right text-end mb-0 large-letters fst-italic">Mrs. Judy Grace C. Reganot</p>
                        </div>
                    </div>
                    <div className="flex-item-container">
                        <p className="mb-0 slide-right text-start fw-bold larger-letters">BRIDE'S PARENTS</p>
                        <div>
                            <p className="slide-left text-start mb-0 large-letters fst-italic">Mr. Edgardo A. Llanillo</p>
                            <p className="slide-left text-start mb-0 large-letters fst-italic">Mrs. Imelda R. Llanillo</p>
                        </div>
                    </div>
                </div>

                <div id="principalSponsors" className="mt-6 text-center">
                    <p className="mt-4 slide-right mb-0 fw-bold larger-letters">P R I N C I P A L &nbsp; S P O N S O R S</p>
                </div>

                <div className="flex-container">
                    <div className="flex-item-container">
                        <p className="slide-right text-end mb-0 large-letters fst-italic">Archt. Renato B. Tayong</p>
                        <p className="slide-right text-end mb-0 large-letters fst-italic">Mr. Siegfred T. Tejam</p>
                        <p className="slide-right text-end mb-0 large-letters fst-italic">Mr. Jesus Renato A. Abanes</p>
                        <p className="slide-right text-end mb-0 large-letters fst-italic">Mr. Gordon R. Neri</p>
                        <p className="slide-right text-end mb-0 large-letters fst-italic">Engr. Mario G. Tan</p>
                        <p className="slide-right text-end mb-0 large-letters fst-italic">Engr. Sandy C. Galila</p>
                    </div>
                    <div className="flex-item-container">
                        <p className="slide-left text-start mb-0 large-letters fst-italic">Mrs. Emily E. Tayong</p>
                        <p className="slide-left text-start mb-0 large-letters fst-italic">Mrs. Marylou R. Tejam</p>
                        <p className="slide-left text-start mb-0 large-letters fst-italic">Mrs. Bernardita C. Abanes</p>
                        <p className="slide-left text-start mb-0 large-letters fst-italic">Ms. Heidi N. Toledo</p>
                        <p className="slide-left text-start mb-0 large-letters fst-italic">Mrs. Ma. Soccoro S. Tan</p>
                        <p className="slide-left text-start mb-0 large-letters fst-italic">Mrs. Virgie O. Galila</p>
                    </div>
                </div>
                
                <div id="entourage-detect" className="nav-detect"></div>
                <div className="flex-container my-4">
                    <div className="flex-item-container">
                        <p className="mb-0 slide-right text-end fw-bold larger-letters">BEST MAN</p>
                        <div>
                            <p className="slide-right text-end mb-0 large-letters fst-italic">Jason Paul C. Reganot</p>
                        </div>
                    </div>
                    <div className="flex-item-container">
                        <p className="mb-0 slide-right text-start fw-bold larger-letters">MAID OF HONOR</p>
                        <div>
                            <p className="slide-left text-start mb-0 large-letters fst-italic">Beverlyn D. Parba</p>
                        </div>
                    </div>
                </div>
                
                <div className="flex-container my-4">
                    <div className="flex-item-container">
                        <p className="mb-0 slide-right text-end fw-bold larger-letters">GROOMSMEN</p>
                        <div>
                            <p className="slide-right text-end mb-0 large-letters fst-italic">Jerome R. Llanillo</p>
                            <p className="slide-right text-end mb-0 large-letters fst-italic">Eric B. Godinez</p>
                            <p className="slide-right text-end mb-0 large-letters fst-italic">Earl Vincent F. Subla</p>
                            <p className="slide-right text-end mb-0 large-letters fst-italic">Michael Angelo F. Subla</p>
                        </div>
                    </div>
                    <div className="flex-item-container">
                        <p className="mb-0 slide-right text-start fw-bold larger-letters">BRIDESMAID/MAN</p>
                        <div>
                            <p className="slide-left text-start mb-0 large-letters fst-italic">Angelika Faith R. Tejam</p>
                            <p className="slide-left text-start mb-0 large-letters fst-italic">Chezka Ana E. Cañedo</p>
                            <p className="slide-left text-start mb-0 large-letters fst-italic">Klytelle Q. Rivera</p>
                            <p className="slide-left text-start mb-0 large-letters fst-italic">Ma. Chrismarie L. Fuentes</p>
                            <p className="slide-left text-start mb-0 large-letters fst-italic">Jeson C. Melencion</p>
                        </div>
                    </div>
                </div>

                <div id="principalSponsors" className="mt-6">
                    <p className="mt-4 slide-right fw-bold larger-letters">S E C O N D A R Y &nbsp; S P O N S O R S</p>
                </div>
                
                <div className="flex-container">
                    <div className="flex-item-container">
                        <p className="slide-right small-letters fw-bold mb-0 text-end">CANDLE</p>
                        <div className="mt-1">
                            <p className="slide-right text-end mb-0 large-letters fst-italic">Ptr. Nerelle S. Lerios</p>
                            <p className="slide-right text-end mb-0 large-letters fst-italic">Edena R. Macaldo</p>
                        </div>
                    </div>
                    <div className="flex-item-container">
                        <p className="slide-right small-letters fw-bold mb-0 text-start">VEIL</p>
                        <div className="mt-1">
                            <p className="slide-left text-start mb-0 large-letters fst-italic">Ricky S. Magallanes</p>
                            <p className="slide-left text-start mb-0 large-letters fst-italic">Cheryl P. Magallanes</p>
                        </div>
                    </div>
                </div>
                
                <div className="flex-container my-4">
                    <div className="flex-item-container">
                        <p className="slide-right small-letters fw-bold mb-0 text-end">CORD</p>
                        <div className="mt-1">
                            <p className="slide-right text-end mb-0 large-letters fst-italic">Artemio O. Roden</p>
                            <p className="slide-right text-end mb-0 large-letters fst-italic">Elizabeth R. Metillo</p>
                        </div>
                    </div>
                    <div className="flex-item-container">
                        <p className="slide-right small-letters fw-bold mb-0 text-start">BIBLE BEARER</p>
                        <div className="mt-1">
                            <p className="slide-left text-start mb-0 large-letters fst-italic">Rey Christopher B. Castillo</p>
                        </div>
                    </div>
                </div>
                
                <div className="flex-container my-4">
                    <div className="flex-item-container">
                        <p className="slide-right small-letters fw-bold mb-0 text-end">RING BEARER</p>
                        <div className="mt-1">
                            <p className="slide-right text-end mb-0 large-letters fst-italic">Dirk Jhonsel V. Cañedo</p>
                        </div>
                    </div>
                    <div className="flex-item-container">
                        <p className="slide-right small-letters fw-bold mb-0 text-start">COIN BEARER</p>
                        <div className="mt-1">
                            <p className="slide-left text-start mb-0 large-letters fst-italic">Zev Zion R. Getil</p>
                        </div>
                    </div>
                </div>

                <div className="my-4">
                    <p className="slide-right fw-bold mb-0 small-letters">FLOWER LADIES</p>
                    <div className="mt-1">
                        <p className="slide-right mb-0 large-letters fst-italic">Trisline P. Cañedo</p>
                        <p className="slide-right mb-0 large-letters fst-italic">Mary Stephanie P. Cañedo</p>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Entourage;