import $ from 'jquery';
import { SlideAction } from '../constants/slide-actions';
import { checkPosition } from './position-checker';
import { Duration } from '../constants/animate-durations';

export function applySlideAction(
  element,
  slideAction,
  seconds = 1600,
  easing = "swing"
) {
  if (!$(element).hasClass("animated")) {
    switch (slideAction) {
      case SlideAction.Up:
        let from = "100px";
        let to = "0px";

        if ($(element).css("top")) {
          let topValueAsString = $(element).css("top");
          let topValueAsInt = parseInt(topValueAsString);

          to = topValueAsString;
          if (topValueAsInt < 0) {
            from = "0px";
          }
        }

        $(element).css({ top: from });
        $(element).animate({ top: to, opacity: "1" }, seconds, easing);
        $(element).addClass("animated");
        break;

      case SlideAction.Left:
        $(element).css({ left: "100px" });
        $(element).animate({ left: "0px", opacity: "1" }, seconds, easing);
        $(element).addClass("animated");
        break;

      case SlideAction.Right:
        $(element).css({ right: "100px" });
        $(element).animate({ right: "0px", opacity: "1" }, seconds, easing);
        $(element).addClass("animated");
        break;

      case SlideAction.Pop:
        if ($(element).css("height")) {
          let heightValueAsString = $(element).css("height");
          let heightValueAsInt = parseInt(heightValueAsString);

          let heightFrom = `${heightValueAsInt - 50}px`;
          let heightTo = heightValueAsInt;

          let widthValueAsString = $(element).css("width");
          let widthValueAsInt = parseInt(widthValueAsString);

          let widthFrom = `${widthValueAsInt - 50}px`;
          let widthTo = widthValueAsInt;

          $(element).css({ height: heightFrom, width: widthFrom });
          $(element).animate({ height: heightTo, width: widthTo, opacity: "1" }, seconds, easing);
          $(element).addClass("animated");
        }
        break;

      default:
        break;
    }
  }
}

window.addEventListener("scroll", function () {
  document.querySelectorAll(".slide-up").forEach((e) => {
    if (checkPosition(e)) {
      applySlideAction(e, SlideAction.Up, Duration.SlideUp);
    }
  });
  document.querySelectorAll(".slide-left").forEach((e) => {
    if (checkPosition(e)) {
      applySlideAction(e, SlideAction.Left, Duration.SlideLeft);
    }
  });
  document.querySelectorAll(".slide-right").forEach((e) => {
    if (checkPosition(e)) {
      applySlideAction(e, SlideAction.Right, Duration.SlideRight);
    }
  });
  document.querySelectorAll(".slide-pop").forEach((e) => {
    if (checkPosition(e)) {
      applySlideAction(e, SlideAction.Pop, Duration.SlidePop);
    }
  });
});
