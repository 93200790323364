import React from 'react';
import AccomodationThumbnail from './Accomodation-Thumbnail';
import AccomodationListItem from './Accomodation-ListItem';

function Accomodation() {
    return (
        <div id="accomodation" className="section">
            <div className="container col-xxl-8 px-4 pt-5 mt-5">
                <div className="d-flex flex-row g-5 py-lg-5 mb-5">
                    <div>
                        <div>
                            <p className="title-letters-cursive-3 cursive-font slide-up">Where to stay</p>
                            <div >
                                <p className="large-letters ps-0 slide-up">There are plenty of accommodation options that cater to all budgets. Group rates are available at these hotels.</p>
                                <AccomodationListItem ItemName="Bayfront Hotel" />
                                <AccomodationListItem ItemName="Radisson Blu Hotel (10 min walk to the wedding venue)" />
                                <p className="large-letters mt-2 ps-0 slide-up">Let us know if you want to book at Bayfront Hotel because we were given a <b>discounted price</b>.</p>
                                <p className="large-letters ps-0 slide-up">Don’t forget to check out <a href="https://www.airbnb.com/s/SM-City-Cebu--Cebu-City--Cebu--Philippines/homes?adults=2&tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&query=SM%20City%20Cebu%2C%20Cebu%20City%2C%20Cebu&flexible_trip_lengths%5B%5D=one_week&monthly_start_date=2024-03-01&monthly_length=3&monthly_end_date=2024-06-01&price_filter_input_type=0&price_filter_num_nights=5&channel=EXPLORE&place_id=ChIJ66ezlB6ZqTMRPEHqHopTArk&date_picker_type=calendar&checkin=2024-07-04&checkout=2024-07-06&source=structured_search_input_header&search_type=autocomplete_click" className="text-decoration-none airbnb-color" target="_blank" rel="noopener noreferrer"><b>Airbnb</b></a> as there are some great options nearby. Be quick, we’re expecting places to book up fast! If you’re struggling please get in touch.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="accomodation-detect" className="nav-detect"></div>
            <div className="container col-xxl-8 px-4 pb-5">
                <div className="d-flex flex-wrap flex-row gap-6">
                        <AccomodationThumbnail  hotelName="Bayfront Hotel"
                                                address="Kaohsiung St, Cebu City, 6000 Cebu"
                                                contactNumber="(032) 230 6777"
                                                websiteUrl="https://northreclamation.bayfronthotelcebu.com/rooms-3" />
                        <AccomodationThumbnail  hotelName="Radisson Blu Hotel, Cebu"
                                                address="Serging Osmena Boulevard, Corner Pope John Paul II Ave, Cebu City, 6000 Cebu"
                                                contactNumber="+63 32 4029900"
                                                websiteUrl="https://www.radissonhotels.com/en-us/hotels/radisson-blu-cebu/rooms" />
                </div>
                <div className="my-5 py-3"></div>
            </div>
        </div>
    );
}

export default Accomodation;