export const topics = [
    {
        name: "Home",
        scrollId: "home"
    },
    {
        name: "Details",
        scrollId: "details"
    },
    {
        name: "Our Story",
        scrollId: "story"
    },
    {
        name: "Venue",
        scrollId: "venue"
    },
    {
        name: "Timeline",
        scrollId: "timeline"
    },
    {
        name: "Entourage",
        scrollId: "entourage"
    },
    {
        name: "Attire Guide",
        scrollId: "dressCode"
    },
    {
        name: "Accomodation",
        scrollId: "accomodation"
    },
    {
        name: "Things To Do",
        scrollId: "thingsToDo"
    },
    {
        name: "FAQ",
        scrollId: "faq"
    },
    {
        name: "RSVP",
        scrollId: "rsvp"
    }
];

