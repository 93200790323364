import React from 'react';
import FAQTile from './FAQ-Tile';
import { SlideAction } from '../constants/slide-actions';

function FAQ() {
    return(
        <div id="faq" className="section">
            <div className="container pb-5 pt-4">
                <p className="title-letters-cursive-1 cursive-font text-center slide-up">F A Q</p>
                <div id="faq-detect" className="nav-detect"></div>
                <div className="d-flex flex-lg-row flex-column flex-wrap justify-content-center">
                    <div className="col-lg-5 col-12 me-lg-4 mb-4">
                        <FAQTile question="Where will the wedding take place?"
                                answer="Bayfront Hotel, Bantayan Ballroom"
                                slide={SlideAction.Right}></FAQTile>
                        <FAQTile question="When is the RSVP deadline?"
                                answer="Please send your response on or before June 28, 2024."
                                slide={SlideAction.Right}></FAQTile>
                        <FAQTile question="What should I wear?"
                                answer="Please refer to the Attire Guide above for more details."
                                slide={SlideAction.Right}></FAQTile>
                        <FAQTile question="What time should I arrive?"
                                answer="We recommend that you arrive 30 minutes before the ceremony starts so you can get settled in and relax before the celebration begins."
                                slide={SlideAction.Right}></FAQTile>
                        <FAQTile question="Where can I park? Is the parking free?"
                                answer="On-site parking is available at the 3rd and 4th floor of the hotel building. Open parking area is also available. Parking is free."
                                slide={SlideAction.Right}></FAQTile>
                        
                    </div>
                    <div className="col-lg-5 col-12 ms-lg-4">
                        <FAQTile question="Gift preference"
                                    answer="Your presence at our wedding is the greatest gift we could ask for! If you would like to honor us with a gift, we would prefer a gift in monetary form."
                                    slide={SlideAction.Left}></FAQTile>
                        <FAQTile question="Can I bring my kids?"
                                    answer="While we love your little ones, our wedding will be an adults-only event (unless included in the entourage) so that everyone can relax and enjoy the evening."
                                    slide={SlideAction.Left}></FAQTile>
                        <FAQTile question="Can I bring a plus one?"
                                    answer="We appreciate your understanding and cooperation in honoring our request for a specific guest count due to space limitations, the authorities of the venue won't accept those who are not on the guest list."
                                    slide={SlideAction.Left}></FAQTile>
                        {/* <FAQTile question="What’s the wedding hashtag?"
                                    answer="Share the love, using our hashtag: #JoshRightTimeForJas"></FAQTile>
                        <FAQTile question="Who can I contact for further information?"
                                    answer="You may contact Imee: 09158401520"></FAQTile> */}
                        <div className="py-2 px-1 m-3 outline-border-white shadow text-center slide-left">
                            <p className="fw-bold small-letters">I NEED DIRECTIONS TO THE WEDDING VENUE</p>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3925.3750237830886!2d123.91862581092059!3d10.311841789767563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a99972f04ee017%3A0x239e29fd87e52e92!2sBayfront%20Hotel%20Cebu%20-%20North%20Reclamation!5e0!3m2!1sen!2sph!4v1708578788322!5m2!1sen!2sph" className="iframe-contain" allowFullScreen="" title="maps" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FAQ;