import React from 'react';
import { Events } from "../constants/timeline-events.js";
import { createTimelineDimensions } from '../services/timeline.js';
import glitter2 from "../images/glitter-gif-2.gif";
import CountdownBox from './CountdownBox.jsx';



class Timeline extends React.Component {
    state = {
        timeRemaining: this.computeTimeRemaining(),
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    }

    componentDidMount() {
        createTimelineDimensions();
        this.startCountdown();
    }

    startCountdown() {
        setInterval(() => {
            this.setState({
                timeRemaining: this.computeTimeRemaining()
            }, this.updateTimeRemaining)
        }, 1000);
    }

    updateTimeRemaining() {
        let hours = new Date().getHours();
        this.setState({
            days: Math.ceil(this.state.timeRemaining / (1000 * 60 * 60 * 24)),
            hours: this.getHours(Math.abs(24 - (hours > 15 ? hours - 15 : hours + 9))),
            minutes: 60 - new Date().getMinutes(),
            seconds: 60 - new Date().getSeconds()
        });
    }

    getHours = (hours) => {
        return hours === 0 ? 24 : hours;
    }

    computeTimeRemaining() {
        let dateToday = new Date();
        let weddingDate = new Date('2024/07/12 15:00:00');
        return Math.abs(weddingDate - dateToday);
    }

    render() {
        let { days, hours, minutes, seconds } = this.state;

        return(
            <div id="timeline" className="section">
                <div className="d-flex flex-lg-row flex-column justify-content-center mt-4 align-items-lg-start align-items-center">
                    <div id="timelineDetails" className="timeline-details">
                        <div className="image-div-cut-excess" style={{width: "80%"}}>
                            <img src={glitter2} className="glitter-gif-2-dimensions object-fit-cover position-relative" alt="glitter"></img>
                        </div>
                        <div className="timeline-schedule-position d-flex flex-column align-items-center justify-content-center h-100">
                            <div>
                                <p className="title-letters-2 fw-300 mb-neg-1 text-center ps-0 slide-up">The Wedding Day</p>
                                <p className="larger-letters serif-font-4 text-center ps-0 slide-up">Friday, 12th July 2024</p>
                            </div>
                            <div className="mt-5 pt-2 d-flex flex-row gap-3 slide-up">
                                <CountdownBox time={days} section="Days" showColon={true} />
                                <CountdownBox time={hours} section="Hours" showColon={true} />
                                <CountdownBox time={minutes} section="Minutes" showColon={true} />
                                <CountdownBox time={seconds} section="Seconds" showColon={false} />
                            </div>
                        </div>
                    </div>
                    <div id="timelineContainer" className="container-grid">
    
                        { Events.map((value, index) => {
                            return (
                                <div key={`event-time-${index}`} id={`event-time-${index}`} className="timeline-event d-flex flex-row align-items-center justify-content-center slide-right">
                                    <p className="large-letters mb-0">{value.time}</p>
                                </div>
                            )
                        })}
    
                        { Events.map((value, index) => {
                            return (
                                <div key={`event-name-${index}`} id={`event-name-${index}`} className="timeline-event d-flex flex-row align-items-center slide-left">
                                    <p className="large-letters mb-0 text-start ps-5">{value.eventName}</p>
                                </div>
                            )
                        })}
    
                        { Events.map((value, index) => {
                            return (
                                <div key={`event-ball-${index}`} id={`event-ball-${index}`} className="slide-up">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="gray" viewBox={value.viewBox}><path d={value.svgPath}/></svg>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div id="timeline-detect" className="nav-detect"></div>
            </div>
        );
    }
}

export default Timeline;