import React from 'react';

const CountdownBox = (props) => {
    return ( 
        

        <div className="d-flex flex-column">
        <div className="d-flex flex-row justify-content-center align-items-center countdown-gap">
            <div className="d-flex flex-column align-items-center">
                <div className="countdown-box countdown-box-width py-md-3 px-md-3 py-2 px-1">
                    <p className="mb-0 countdown-timer-font-size alfa-slab-one-font-style text-center">{props.time}</p>
                </div>
            </div>
            <p className={`mb-0 countdown-colon-size alfa-slab-one-font-style text-center ${props.showColon ? "d-block" : "d-none"}`}>:</p>
        </div>
        <div className="countdown-box-width">
            <p className="mb-0 large-letters text-center fw-300">{props.section}</p>
        </div>
        </div>
     );
}
 
export default CountdownBox;