import $ from 'jquery';
import { checkPosition } from './position-checker';
import { Duration } from '../constants/animate-durations';

var isClicked = false;

export function setLinkBehavior() {
    $(".navbar-item").on("click", (event) => {
        isClicked = true;
        let parent = event.target.parentElement;
        let currentActiveLink = parent.querySelector(".active");
        $(currentActiveLink).removeClass("active");
    
        let self = event.target;
        $(self).addClass("active");

        setTimeout(() => {
          isClicked = false;
        }, Duration.SlideUp)
      });
}

window.addEventListener("scroll", function () {
  document.querySelectorAll(".nav-detect").forEach((e) => {
    if (checkPosition(e) && !isClicked) {
      let sectionId = e.getAttribute("id").replace("-detect","");
      let navItem = $(`#${sectionId}Link`)[0];
      let parent = navItem.parentElement;
      let currentActiveLink = parent.querySelector(".active");
      $(currentActiveLink).removeClass("active");
      $(navItem).addClass("active");
    }
  });
});
