import React from "react";
import logo from "../images/j-logo.jpg";

function Footer () {
    return (
        <div className="section">
            <div className="container">
                <footer className="d-flex flex-wrap justify-content-between align-items-center pt-3 pb-5 mt-4">
                    <hr className="broken-line-black w-75 opacity-50 slide-up mx-auto"></hr>
                    <div className="d-flex flex-column align-items-center justify-content-center w-100">
                        <p className="my-2 small-letters text-center opacity-75 slide-up">© 2024 Joshua & Jassy Wedding Invitation Designs</p>
                        <a href="/" className="text-decoration-none slide-up">
                            <img src={logo} className="footer-img object-fit-cover shadow" alt="logo"></img>
                        </a>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default Footer;