import React from 'react';
import TouristSpot from './TouristSpots-Thumbnail';
import nustar from '../images/nustar.jpg';
import cebuBuildings from '../images/cebu-buildings.jpg';
import ayalaCebu from '../images/ayala.jpg';
import smSeaside from '../images/sm-seaside.jpg';
import ilCorso from '../images/il-corso.jpg';

function ThingsToDo() {
    return (
        <div id="thingsToDo" className="section">
            <div>
                <hr className="broken-line w-75 slide-up my-3 mx-auto"></hr>
                <p className="title-letters-cursive-2 cursive-font text-center slide-up">Things to do</p>
                <p className="large-letters text-center ps-0 mb-5 slide-up">Beyond the celebration, our venue boasts proximity to some incredible attractions for you to explore during your stay.</p>

                <div className="container col-xxl-8 px-4 mb-5 mt-5">
                    <div id="thingsToDo-detect" className="nav-detect"></div>
                    <div className="d-flex flex-wrap flex-lg-row flex-xs-column align-items-sm-start align-items-xs-center g-5 pb-5 mb-5 justify-content-center gap-large">
                        <TouristSpot    imgSrc={smSeaside}
                                        name="SM Seaside" />
                        
                        <TouristSpot    imgSrc={nustar}
                                        name="Nustar" />
                        
                        <TouristSpot    imgSrc={cebuBuildings}
                                        name="Cebu IT Park" />
                        
                        <TouristSpot    imgSrc={ilCorso}
                                        name="Il Corso" />
                        
                        <TouristSpot    imgSrc={ayalaCebu}
                                        name="Ayala Cebu" />
                    </div>
                </div>

                <hr className="broken-line w-75 slide-up mb-0 mx-auto"></hr>
            </div>
        </div>
    );
}

export default ThingsToDo;