import React, { Fragment } from "react";
import NavBar from "./NavBar";
import SpotifySideBar from "./Spotify-Sidebar";
import Welcome from "./Welcome";
import Invitation from "./Invitation";
import Timeline from "./Timeline";
import Entourage from "./Entourage";
import DressCode from "./DressCode";
import Venue from "./Venue";
import Story from "./Story";
import ThingsToDo from "./ThingsToDo";
import Accomodation from "./Accomodation";
import FAQ from "./FAQ";
import RSVP from "./RSVP";
import Footer from "./Footer";
import letterSeal from "../images/letter-seal.png"
import glitter from "../images/glitter-gif.gif"
import roseSvg from "../images/envelope-seal-logo.svg"
import VerticalLine from "./Vertical-Line";
import $ from "jquery";

class InvitationLetter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            parameterRoot: props.parameterRoot
        }
    }

    goToApp = (event, root) => {
        root.render(
            <Fragment>
                <NavBar />
                <SpotifySideBar />
                <Welcome />
                <Invitation />
                <VerticalLine id="story" />
                <Story />
                <VerticalLine />
                <Venue />
                <Timeline />
                <Entourage />
                <DressCode />
                <Accomodation />
                <ThingsToDo />
                <FAQ />
                <RSVP />
                <Footer />
            </Fragment>
        );
        this.playMusic();
    }
    
    playMusic = () => {
        let musicPlayer = $("#musicPlayer")[0];
        musicPlayer.volume = 0.2;
        musicPlayer.play();
    }

    render() {
        return (
            <div id="invitationLetter" className="section">
                <div className="h-100 mt-3 pt-1">
                    <div className="d-flex flex-column h-100 justify-content-center align-items-center overflow-hidden">
                        <div>
                            <div className="text-center">
                                <p className="title-letters-3 mb-neg-1">Y O U &nbsp; A R E &nbsp; I N V I T E D !</p>
                                <p className="title-letters-cursive-1 mb-neg-1 cursive-font-2">Joshua & Jassy</p>
                            </div>
                            <div className="envelope">
                                <div className="envelope-line-1 z-0"></div>
                                <div className="envelope-line-2 z-0"></div>
                                <div className="envelope-line-3 z-0"></div>
                                <div className="envelope-line-4 z-0"></div>
                                <div className="envelope-seal position-relative z-1 d-flex flex-row justify-content-center">
                                    <div className="cursor-pointer z-1" onClick={event => this.goToApp(event, this.state.parameterRoot)}>
                                        <img src={letterSeal} className="envelope-seal-dimensions" alt="letter seal"></img>
                                    </div>
                                    <div className="position-absolute cursor-pointer envelope-rose-position z-2" onClick={event => this.goToApp(event, this.state.parameterRoot)}>
                                        <img src={roseSvg} className="envelope-rose-dimensions" alt="rose"></img>
                                    </div>
                                    <div className="position-absolute cursor-pointer envelope-initials-position z-2" onClick={event => this.goToApp(event, this.state.parameterRoot)}>
                                        <p style={{ rotate: "319deg" }} className="smaller-cursive-letters cursive-font-2">J & J</p>
                                    </div>
                                    <div className="position-absolute envelope-glitter-position">
                                        <img src={glitter} className="object-fit-cover envelope-glitter-dimensions" alt="glitter"></img>
                                    </div>
                                    
                                    <div className="envelope-message-position text-center position-relative z-1">
                                        <p className="small-cursive-letters cursive-font-2 mb-neg-2">Save the date</p>
                                        <p className="smallest-letters serif-font-1">07.12.2024</p>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mt-4 py-5 w-lg-75 pnest-hub-max-letter-instructions">
                                <p className="x-large-letters mb-0">Click the envelope seal to unveil our wedding website</p>
                                <p className="x-large-letters">and explore all the details of our special day.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default InvitationLetter;