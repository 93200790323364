import $ from 'jquery';
import { Events } from '../constants/timeline-events';
import { PhoneScreen } from '../constants/phone-screen-sizes';

export function createTimelineDimensions() {
    computeGridTemplateRows();
    createTimelineDetails();
}

function computeGridTemplateRows() {
    var element = document.getElementsByClassName("container-grid")[0];

    let addGridTemplateRows = getRowTemplate();
    let initialRow = getInitialRowSize();
    let finalGridTemplateRows = initialRow;
    for (let i = 0; i < Events.length; i++) {
        finalGridTemplateRows += ` ${addGridTemplateRows}`;
    }
    $(element).css("grid-template-rows", finalGridTemplateRows);

    let totalRowHeight = computeHeightOfTimeline(finalGridTemplateRows);

    let timelineDetails = document.getElementsByClassName("timeline-details")[0];
    $(timelineDetails).css("height", totalRowHeight);
}

function createTimelineDetails() {
    for (let i = 0, row = 2; i < Events.length; i++, row += 3) { 
        let eventTimeElement = $(`#event-time-${i}`)[0];
        let eventNameElement = $(`#event-name-${i}`)[0];
        let eventBallElement = $(`#event-ball-${i}`)[0];
        $(eventTimeElement).css("grid-area", `${row} / 2 / span 1 / span 1`);
        $(eventNameElement).css("grid-area", `${row} / 6 / span 1 / span 1`);
        $(eventBallElement).css("grid-area", `${row} / 3 / span 1 / span 3`);


        if (i < Events.length - 1) {
            $('#timelineContainer').append(`<div id='timeline-${i + 1}' class="d-flex flex-row justify-content-center align-items-center h-100 w-100 slide-up">
                                                <div class="timeline-gap">

                                                </div>
                                            </div>`);
            let timelineElement = $(`#timeline-${i + 1}`)[0];
            $(timelineElement).css("grid-area", `${row + 1} / 4 / span 2 / span 1`);
        }
    }
}

function computeHeightOfTimeline(finalGridTemplateRows) {
    let rows = finalGridTemplateRows.split(" ");
    let cleanRowsArr = rows.map(r => r.replace("px", ""));
    let totalRowHeight = 0;
    let borderTop = 1;
    let borderBottom = 1;
    cleanRowsArr.forEach(cr => {
        let rowHeight = parseInt(cr);
        totalRowHeight += rowHeight;
    });

    return totalRowHeight + borderTop + borderBottom;
}

function getRowTemplate() {
    let width = window.screen.width;

    if (width <= PhoneScreen.IPhone12Pro) {
        return "24px 25px 20px";
    }

    if (width <= PhoneScreen.Pixel7) {
        return "21px 30px 25px";
    }

    if (width <= PhoneScreen.IPhone14ProMax) {
        return "21px 25px 22px";
    }

    if (width <= PhoneScreen.SurfaceDuo) {
        return "21px 35px 45px";
    }

    if (width <= PhoneScreen.IPadAir) {
        return "21px 35px 35px";
    }

    if (width <= PhoneScreen.IPadPro) {
        return "21px 35px 35px";
    }

    return "36px 20px 80px";
}

function getInitialRowSize() {
    let width = window.screen.width;

    if (width <= PhoneScreen.IPhone12Pro) {
        return "50px";
    }

    if (width <= PhoneScreen.Pixel7) {
        return "50px";
    }

    if (width <= PhoneScreen.IPhone14ProMax) {
        return "50px";
    }

    if (width <= PhoneScreen.SurfaceDuo) {
        return "50px";
    }

    if (width <= PhoneScreen.IPadAir) {
        return "60px";
    }

    if (width <= PhoneScreen.IPadPro) {
        return "60px";
    }

    return "100px";
}

$(window).on("resize", function () {
    let width = window.screen.width;
    if (width === PhoneScreen.IPhone12Pro || 
        width === PhoneScreen.Pixel7 || 
        width === PhoneScreen.IPhone14ProMax || 
        width === PhoneScreen.SurfaceDuo || 
        width === PhoneScreen.IPadAir || 
        width === PhoneScreen.IPadPro) {
        computeGridTemplateRows(getRowTemplate(), getInitialRowSize());
    }
});