import React from 'react';
import reception from "../images/reception.png";

function Venue() {
    return (
        <div id="venue" className="section">
            <div className="container col-xxl-8 px-4 py-5 mt-5">
                <div className="d-flex flex-lg-row flex-column align-items-center gap-3">
                    <div className="col-lg-7">
                        <p className="title-letters-cursive-2 cursive-font slide-up">Venue</p>
                        <div>
                            <p className="large-letters slide-up">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-geo-alt-fill pin-location-dimensions pin-location-color" viewBox="0 0 16 16">
                                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
                                </svg><b> Bayfront - Bantayan Ballroom</b>.
                            </p>
                            <div className="mb-1">
                                <p className="large-letters mb-neg-1 slide-up">Kaohshiung Street</p>
                                <p className="large-letters mb-neg-1 slide-up">North Reclamation Area</p>
                                <p className="large-letters slide-up">Cebu City</p>
                            </div>
                            <p className="large-letters slide-up">It's a lovely indoor venue designed by Carlo Abaquita.</p>
                            <p className="large-letters slide-up">Plenty of <b>parking</b> at the <b>3rd </b>and <b>4th floor</b>.</p>
                            <p className="large-letters slide-up">Just <b>step hard on the gas</b> because bayfront's parking has a pretty <b>steep driveway</b>.</p>
                            <p className="large-letters slide-up">There is an <b>open parking space</b> - Just to the left of the hotel if you are facing Bayfront from SM.</p>
                            <p className="large-letters slide-up">Ceremony and reception will both be held at Bantayan Ballroom, Bayfront Hotel.</p>
                        </div>
                        <div className=" my-lg-5 pb-lg-5">
                            <hr className="broken-line-thin w-50 mb-0 slide-up"></hr>
                            <p className="large-letters slide-up">Ceremony starts at 3PM.</p>
                        </div>
                    </div>
                    <div className="col-lg-5 col-7">
                        <img src={reception} className="img-fluid slide-pop" alt="reception"></img>
                    </div>
                    <div id="venue-detect" className="nav-detect"></div>
                </div>
            </div>
        </div>
    );
}

export default Venue;