import React from 'react';
import coupleImg from '../images/couple-picture.png';
import flowerImg from "../images/flower.svg";
import initialsRing from "../images/initials-ring.svg";
import glitter2 from "../images/glitter-gif-2.gif";

function Story() {
    return (
        <div className="section">
            <div className="col-xxl-8 px-4 pb-5 pt-2 mt-4 mb-5 w-100">
                <div className="pt-3 pb-4 mb-5">
                    <div className="d-flex flex-lg-row flex-column-reverse gap-lg-3 align-items-center">
                        <div className="col-lg-4 col-9">
                            <div className="mt-lg-0 mt-4 w-lg-100 w-75 h-lg-100 h-50 mx-auto">
                                <img src={coupleImg} className="d-block img-fluid slide-pop" alt="couple" loading="lazy"/>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="mx-lg-0 mx-auto">
                                <p className="title-letters-cursive-2 cursive-font-2 slide-up">Our Story</p>
                                <div>
                                    <p className="large-letters ps-0 slide-up">Our love story began through a shared love for Jesus.</p>
                                    <p className="large-letters ps-0 slide-up">We met through a Christian group formed at our workplace.</p>
                                    <p className="large-letters ps-0 slide-up">We share the same faith and interests, and we both have a strong desire to serve God through music.</p>
                                    <p className="large-letters ps-0 slide-up">Jassy is a church singer.</p>
                                    <p className="large-letters ps-0 slide-up">Joshua is a church guitarist.</p>
                                    <p className="large-letters ps-0 slide-up">Our love story serves as a reminder that love can come in unexpected ways.</p>
                                    <p className="large-letters ps-0 slide-up">As we approach our wedding day, we reflect on the chapters that brought us together—friendship, shared dreams, a heartfelt proposal, and unwavering support.</p>
                                    <p className="large-letters ps-0 slide-up">Our love story continues, and we can't wait to start the next chapter and write our happily ever after together.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="story-detect" className="nav-detect"></div>

            <div id="storyBanner">
                <div className="image-div-cut-excess">
                    <img src={glitter2} className="glitter-gif-2-dimensions object-fit-cover position-relative" alt="glitter"></img>
                </div>
                <div className="px-4 pb-5 pt-4 mt-4 text-center">
                    <div className="mt-3">
                        <img src={initialsRing} className="initials-ring-img position-relative slide-up" alt="ring"></img>
                        <img src={flowerImg} className="flower-img position-relative" alt="flower"></img>
                    </div>
                    <div>
                        <p className="title-letters-cursive-3 cursive-font slide-up">I choose you</p>
                        <p className="large-letters mb-0 ps-0 slide-up">TODAY, TOMORROW</p>
                        <p className="large-letters ps-0 slide-up">AND FOR ALL OF THE OTHER TOMORROWS STILL YET TO COME</p>
                    </div>
                    <div className="mt-4">
                        <p className="large-cursive-letters cursive-font-2 ps-0 slide-up">Joshua & Jassy</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Story;