import React, { useState } from 'react';
import { postData } from '../services/services';
import { ValidationTypes, validate, validateSendEmail } from '../services/validations';

function RSVP() {
    const [confirmationMsg, setConfirmationMsg] = useState("");
    const [isRsvpMsgDisplay, setIsRsvpMsgDisplay] = useState(false);
    const [rsvpTitle, setRsvpTitle] = useState("Hope you can make it!");
    const [firstName, setFirstName] = useState("");
    const [firstNameErrorMsg, setFirstNameErrorMsg] = useState("");
    const [lastName, setLastName] = useState("");
    const [lastNameErrorMsg, setLastNameErrorMsg] = useState("");
    const [email, setEmail] = useState("");
    const [emailErrorMsg, setEmailErrorMsg] = useState("");
    const [disabledAttr, setDisabledAttr] = useState("");
    const [submitBtnText, setSubmitBtnText] = useState("Confirm"); 
    const [spinnerCollapse, setspinnerCollapse] = useState("collapse");

    const firstNameChange = (event) => setFirstName(event.target.value);
    const lastNameChange = (event) => setLastName(event.target.value);
    const emailChange = (event) => setEmail(event.target.value);
    

    const confirm = async () => {
        let givenFirstName = firstName;
        let givenLastName = lastName;
        let body = {
            firstName: givenFirstName,
            lastName: givenLastName
        };
        let errors = validateFields();
          
        if (errors.length === 0) {
            submissionOnGoing(true);
            let data = await postData("/isinvited", body);

            let confirmationMsg = "";

            if (data.isInvited) {
                let { firstName, lastName, suffix } = data.guest;

                let partnerMsg = "";
                if (data.partnerGuest) {
                    let partnerGuest = data.partnerGuest;
                    let partnerFirstName = partnerGuest.firstName;
                    let partnerLastName = partnerGuest.lastName;
                    let partnerSuffix = partnerGuest.suffix;

                    
                    partnerMsg = 
                        <div>
                            <p className="larger-letters text-center position-relative ps-0 mb-0 slide-up-now">&</p>
                            <p className="larger-letters text-center position-relative ps-0 fw-bold slide-up-now">{ `${partnerSuffix} ${partnerFirstName} ${partnerLastName}`}</p>
                        </div>;
                }

                confirmationMsg = 
                    <div>
                        <p className="larger-letters text-center position-relative ps-0 mb-0 slide-up-now">Hi <b>{ `${suffix} ${firstName} ${lastName}`}</b></p>
                        {partnerMsg}
                        <p className="large-letters text-center position-relative ps-0 mb-0 slide-up-now">We reserved <span className="fw-bold text-danger">{partnerMsg === "" ? 1 : 2}</span> seat{partnerMsg === "" ? "" : "s"} for you.</p>
                        <p className="large-letters text-center position-relative ps-0 slide-up-now">We look forward to celebrating with you. ♡</p>
                        <p className="display-4 cursive-font-2 text-center mt-3 position-relative ps-0 slide-up-now">Thank you!</p>
                    </div>;

                if (email !== "")
                    sendEmail(data, email);

                setRsvpTitle("Yehey, See you there!");
            }
            else {
                confirmationMsg =
                    <div>
                        <p className="larger-letters text-center position-relative ps-0 mb-0 slide-up-now">We're sorry but there are only limited seats.</p>
                        <p className="larger-letters text-center position-relative ps-0 mb-0 slide-up-now">We appreciate you taking the time to go through our invitation site.</p>
                        <p className="display-4 cursive-font-2 text-center mt-3 position-relative ps-0 slide-up-now">Thank you!</p>
                    </div>

                setRsvpTitle("");
            }

            setConfirmationMsg(confirmationMsg);

            submissionOnGoing(false);
            setIsRsvpMsgDisplay(true);
        }
    };

    async function sendEmail(data, email) {
        let errors = await validateEmail(data.guest.hasEmailSent, email);

        if (errors.length === 0) {
            let body = {
                email: email,
                guest: data.guest,
                partnerGuest: data.partnerGuest
            }

            await postData("/requestemail", body);
        }
    }

    async function validateEmail(hasEmailSent, emailAddress) {
        let errors = [];

        errors.push(await validateSendEmail(hasEmailSent, emailAddress));
        setEmailErrorMsg(errors[0]);

        return errors.filter(e => e !== null);
    }

    function validateFields() {
        let givenFirstName = firstName;
        let givenLastName = lastName;
        let givenEmail = email;
        let errors = [];

        errors.push(validate("First name", givenFirstName, true, ValidationTypes.Name));
        errors.push(validate("Last name", givenLastName, true, ValidationTypes.Name));
        errors.push(validate("Email", givenEmail, true, ValidationTypes.Email));

        setFirstNameErrorMsg(errors[0]);
        setLastNameErrorMsg(errors[1]);
        setEmailErrorMsg(errors[2]);

        return errors.filter(e => e !== null);
    }

    function submissionOnGoing(isOnGoing) {
        if (isOnGoing) {
            setDisabledAttr("disabled");
            setSubmitBtnText("Loading...");
            setspinnerCollapse("");
        }
        else {
            setDisabledAttr("");
            setSubmitBtnText("Submit");
            setspinnerCollapse("collapse");
        }
    }

    return (
        <div id="rsvp" className="section">
            <div className="container col-xxl-8 px-4 py-5">
                <div className="my-5">
                    <p className="mb-5 pb-5 title-letters-cursive-2 cursive-font-2 text-center slide-up">{rsvpTitle}</p>
                    <div className={`d-flex flex-lg-row-reverse flex-column-reverse g-5 justify-content-start ${isRsvpMsgDisplay ? "d-none" : "d-block"}`}>
                        <div className="col-lg-6 mt-lg-0 mt-4">
                            <form>
                                <div className="mb-3">
                                    <div className="mb-2 slide-up">
                                        <label htmlFor="inputFirstName" className="form-label large-letters">First name<span className="required">*</span></label>
                                        <input onChange={firstNameChange} type="text" className="form-control" id="inputFirstName"></input>
                                        <p className="smallest-letters fst-italic text-danger">{firstNameErrorMsg}</p>
                                    </div>
                                    
                                    <div className="mb-2 slide-up">
                                        <label htmlFor="inputLastName" className="form-label large-letters">Last name<span className="required">*</span></label>
                                        <input onChange={lastNameChange} type="text" className="form-control" id="inputLastName"></input>
                                        <p className="smallest-letters fst-italic text-danger">{lastNameErrorMsg}</p>
                                    </div>

                                    <div className="mb-neg-1 slide-up">
                                        <label htmlFor="inputEmail" className="form-label large-letters">Email address <span className="text-body-secondary">(Optional)</span></label>
                                        <input onChange={emailChange} type="email" className="form-control" id="inputEmail" aria-describedby="emailConfirmationMsg"></input>
                                        <p className="smallest-letters fst-italic text-danger mb-0">{emailErrorMsg}</p>
                                        <p id="emailConfirmationMsg" className="form-text smallest-latters fst-italic">*This email will be sent a confirmation receipt with important reminders once invite is confirmed.</p>
                                    </div>
                                </div>
                                <div className="slide-up">
                                    <button type="button" className={`${disabledAttr} btn btn-primary shadow`} onClick={confirm}>
                                        <span className={`spinner-border spinner-border-sm ${spinnerCollapse}`} aria-hidden="true"></span>
                                        <span className="fw-bold"> {submitBtnText}</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="px-lg-5 my-lg-2">
                            <div>
                                <p className="large-letters ps-0 slide-up">Proper headcount is important to us because there are only limited seats.</p>
                                <p className="large-letters ps-0 slide-up">Please confirm and check if you are invited using the form.</p>
                                <p className="large-letters ps-0 slide-up">Final headcount will be recorded on the <b>28th of June</b>.</p>
                                <p className="large-letters ps-0 slide-up">Any submission after the 28th of June will <b>NOT</b> be counted.</p>
                                <p className="large-letters ps-0 slide-up">Thank you very much :)</p>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center">
                            {confirmationMsg}
                    </div>
                </div>
            </div>
            <div id="rsvp-detect" className="nav-detect"></div>
        </div>
    );
}

export default RSVP;