import React from "react";

function AccomodationThumbnail(props) {
    return (
        <div className="me-5">
            <p className="title-letters-3 slide-up">{props.hotelName}</p>
            <div>
                <p className="large-letters ps-0 mb-0 slide-up">{props.address}</p>
                <p className="large-letters ps-0 mb-0 slide-up">{props.contactNumber}</p>
                <a className="mt-1 slide-up px-3 py-2 shadow btn btn-primary" href={props.websiteUrl} target="_blank" rel="noopener noreferrer">
                    <p className="smaller-letters mb-0 not-selectable fw-bold">BOOK</p>
                </a>
            </div>
        </div>
    )
}

export default AccomodationThumbnail;