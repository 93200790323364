import React from "react";

class VerticalLine extends React.Component {
    render() {
        return (
            <div id={this.props.id} className="d-flex flex-row justify-content-center wedding-background align-items-center">
                <div className="pt-5 mt-5"></div>
                <hr className="mb-0 broken-horizonal-line slide-up"></hr>
            </div>
        )
    }
}

export default VerticalLine;
