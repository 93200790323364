import React from "react";
import { SlideAction } from "../constants/slide-actions";

function FAQTile(props) {

    let slide = "slide-up";
    if (props.slide === SlideAction.Left) {
        slide = "slide-left";
    }
    else if (props.slide === SlideAction.Right) {
        slide = "slide-right";
    }
    else if (props.slide === SlideAction.Pop) {
        slide = "slide-pop";
    }
    return (
        <div className={`py-2 px-4 m-3 outline-border-white shadow text-center ${slide}`}>
            <p className="fw-bold large-letters text-uppercase">{props.question}</p>
            <p className="large-letters">{props.answer}</p>
        </div>
    )
}

export default FAQTile;