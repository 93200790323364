import { topics } from "../constants/topics";
import React from 'react';
import $ from "jquery";
import { setLinkBehavior } from "../services/navbar";

function intializeLinks() {
  $("a[href|='#home']").addClass("active");
}

class NavBar extends React.Component {
  componentDidMount() {
    intializeLinks();
    setLinkBehavior();
  }

  render() {
    return (
      <div className="sticky z-3 pt-1 ps-2 shadow">
        <div className="container h-100 primary-color">
          <div className="nav-scroller d-flex flex-row align-items-center h-100">
            <nav className="nav nav-underline d-flex flex-row justify-content-between col-12">
              {topics.map((value, index) => <a key={value.scrollId} className="nav-item py-neg-1 navbar-item text-decoration-none font-weight-heavy small-letters" id={`${value.scrollId}Link`} href={`#${value.scrollId}`} role="button"><span className="sans-font-1 fw-600 pe-none">{`${value.name}`}</span></a>)}
            </nav>
          </div>
        </div>
      </div>
    );
  }
}


export default NavBar;
