import React from 'react';

function DressCode() {
    return(
        <div id="dressCode" className="section">
            <div className="d-flex flex-column align-items-center px-lg-4 py-5 mt-3 mx-auto w-lg-75">
                <hr className="broken-line w-75 slide-up mb-3 mx-auto"></hr>
                <div className="mb-2 mt-4">
                    <p className="title-letters-cursive-2 cursive-font-2 slide-up">Attire Guide</p>
                </div>
                <p className="slide-up fw-bold large-letters">FORMAL ATTIRE</p>
                <div className="d-flex flex-column text-center gap-2">
                    <div>
                        <div className="mt-3">
                            <p className="slide-up mt-3 fw-bold large-letters">PRINCIPAL SPONSORS</p>
                            <div>
                                <p className="slide-up mb-0 large-letters">LADIES: Beige long gown/dress</p>
                                <div className="d-flex flex-row align-items-center justify-content-center mt-2">
                                    <div className="slide-right beige-1 color-ball-dimensions me-2"></div>
                                    <div className="slide-right beige-2 color-ball-dimensions me-2"></div>
                                    <div className="slide-up beige-3 color-ball-dimensions me-2"></div>
                                    <div className="slide-left beige-4 color-ball-dimensions me-2"></div>
                                    <div className="slide-left beige-5 color-ball-dimensions me-2"></div>
                                </div>
                                <p className="slide-up large-letters mt-2">GENTLEMEN: Black suit & tie</p>
                                <div className="d-flex flex-row align-items-center justify-content-center mt-2">
                                    <div className="slide-up black color-ball-dimensions me-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="mt-5">
                            <p className="slide-up mt-3 fw-bold large-letters">GUESTS</p>
                            <p className="slide-up mb-0 large-letters">LADIES: Long or cocktail dress</p>
                            <p className="slide-up large-letters">GENTLEMEN: Long sleeve, polo, slacks or suit</p>
                        </div>

                        <div className="d-flex flex-row align-items-center justify-content-center mt-2">
                            <div className="slide-right light-pink color-ball-dimensions me-2"></div>
                            <div className="slide-right pink color-ball-dimensions me-2"></div>
                            <div className="slide-right light-blue color-ball-dimensions me-2"></div>
                            <div className="slide-left blue color-ball-dimensions me-2"></div>
                            <div className="slide-left light-purple color-ball-dimensions me-2"></div>
                            <div className="slide-left purple color-ball-dimensions"></div>
                        </div>
                    </div>
                        
                    <div className="mt-5 mb-5">
                        <p className="slide-up mb-0 large-letters fst-italic">Please avoid wearing jeans and casual attire.</p>
                    </div>
                </div>

                <hr className="broken-line w-75 slide-up mt-5 pt-5 mx-auto"></hr>
                <div id="dressCode-detect" className="nav-detect"></div>
            </div>
        </div>
    );
}

export default DressCode;