import { postData } from "./services";

const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const ValidationTypes = {
    Name: 0,
    Email: 1
}

export const validate = (field, value, isRequired, validationType) => {

    let errorMsg = "";
    if (validationType === ValidationTypes.Name) {
        if (value === "" && isRequired) {
            errorMsg = `${field} is required.`;
        }
    }
    else if (validationType === ValidationTypes.Email) {
        if (value !== "" && !validateEmail(value)) {
            errorMsg = "Please input a valid email."
        }
    }

    return errorMsg === "" ? null : errorMsg;
};

export const validateSendEmail = async (hasEmailSent, emailAddress) => {
    let errorMsg = "";

    if (hasEmailSent) {
        errorMsg = "You have been sent a confirmation receipt already. Please check your inbox or spam folder.";
    }
    else {
        let body = {
            email: emailAddress
        }
        let data = await postData("/isemailused", body);
    
        if (data.isEmailUsed) {
            errorMsg = "This email has been sent a confirmation receipt already.";
        }
    }

    return errorMsg === "" ? null : errorMsg;
}