import React from 'react';
import { applySlideAction } from '../services/animations';
import { SlideAction } from '../constants/slide-actions';
import { AnimateEasings } from '../constants/animate-easings';
import flowerPetals from "../images/flower-petals.png"
import couple from "../images/couple.png"


function intializeSlides() {
    document.querySelectorAll("#homeSlide .slide-up").forEach((e) => {
      applySlideAction(e, SlideAction.Up, 1200);
    });
    document.querySelectorAll("#homeSlide .slide-left").forEach((e) => {
      applySlideAction(e, SlideAction.Left, 1200, AnimateEasings.Linear);
    });
    document.querySelectorAll("#homeSlide .slide-right").forEach((e) => {
      applySlideAction(e, SlideAction.Right, 1100, AnimateEasings.Linear);
    });
}


  class Welcome extends React.Component {
    componentDidMount() { 
        intializeSlides();
    } 

    render() {
        return (
            <div id="home" className="section">
                <div id="homeSlide">
                    <div className="col-lg-12 py-3">
                        <div className="d-flex flex-column py-5 mt-5 text-center gap-3 align-items-start justify-content-lg-start justify-content-center flex-home">
                            <div className="col-lg-9 col-xl-6 col-8 mt-5 ps-lg-5">
                                <div className="d-flex flex-column align-items-center mt-lg-5 pt-lg-5 text-start">
                                    <div className="ps-lg-5 ps-2">
                                        <p className="title-letters-1-half abhaya-libre-font-style fw-500 mb-neg-3 z-2 slide-up">J O S H U A &nbsp; E L I</p>
                                        <p className="title-letters-1-half abhaya-libre-font-style fw-500 text-center mb-neg-3 z-2 slide-up">&</p>
                                        <p className="title-letters-1-half abhaya-libre-font-style fw-500 mb-0 z-2 slide-up">J A S M I N &nbsp; A N A I S</p>
                                    </div>
                                    <div className="mt-5 d-flex flex-column align-items-center">
                                        <p className="title-letters-cursive-4 cursive-font-2 z-2 mt-5 pt-4 mb-neg-1 slide-up">Save the date</p>
                                        <p className="larger-letters z-2 mb-neg-1 serif-font-4 slide-up">12th July, 3 PM</p>
                                    </div>
                                </div>
                            </div>
    
                            <div className="image-div-cut-excess">
                                <img src={couple} className="couple-img-dimensions position-relative z-1" alt="couple"></img>
                            </div>
    
                            <div className="image-div-cut-excess">
                                <img src={flowerPetals} className="flower-petals-img-dimensions object-fit-cover position-relative" alt="flower petals"></img>
                            </div>
                        </div>
                        <div className="my-5 py-5"></div>
                    </div>
                </div>
                <div id="home-detect" className="nav-detect"></div>
            </div>
        );
    }
}

export default Welcome;