import React from 'react';
import initialsRing from "../images/initials-ring.svg";
import glitter2 from "../images/glitter-gif-2.gif";

function Invitation() {
    return(
        <div id="details" className="section">
            <div className="image-div-cut-excess">
                <img src={glitter2} className="glitter-gif-2-dimensions object-fit-cover position-relative" alt="glitter"></img>
            </div>
            <div className="px-4 pb-5 pt-4 mt-4 text-center">
                <div className="mt-3 slide-up">
                    <img src={initialsRing} className="initials-ring-img position-relative" alt="ring"></img>
                    <p className="title-letters-3 cursive-font-2 position-relative d-inline initial-first-J">J</p>
                    <p className="title-letters-3 cursive-font-2 position-relative d-inline initial-amp">&</p>
                    <p className="title-letters-3 cursive-font-2 position-relative d-inline initial-second-J">J</p>
                </div>
                <p className="title-letters-3 slide-up">You are invited!</p>
                <div className="col-lg-4 mx-auto mt-5">
                    <p className="large-letters slide-up fw-bolder">Ecclesiastes 3:11-12</p>
                    <p className="large-letters mb-4 slide-up">He has made everything beautiful in its time. He has also set eternity in the human heart; yet no one can fathom what God has done from beginning to end. I know that there is nothing better for people than to be happy and to do good while they live.</p>
                </div>
                <h4 className="display-2 cursive-font-2 slide-up mt-3">Save the date</h4>
                <p className="large-letters mb-2 slide-up fw-bold">Fri 12th July</p>
                <p className="large-letters mb-2 slide-up fw-bold">07. 12. 2024 | 3 PM</p>
                <div className="mt-5">
                    <p className="large-letters slide-up mb-0">Please join us as we celebrate God's goodness. It will be a great blessing if you can come.</p>
                    <p className="large-letters slide-up mb-0">Pastelle-themed event.</p>
                    <p className="large-letters slide-up">Ceremony at Bayfront - Bantayan Ballroom</p>
                </div>
                <div id="details-detect" className="nav-detect"></div>
                <div className="mt-2">
                    <p className="large-letters slide-up">Reception to follow</p>
                </div>
                <div className="mt-2">
                    <p className="large-letters slide-up fw-bolder">Please respond before June 28 :)</p>
                </div>
                <div className="d-flex flex-row mb-5 pb-5 justify-content-center align-items-center">
                    <a className="mt-1 slide-up px-3 py-2 shadow btn btn-secondary" href="#rsvp">
                        <p className="smaller-letters mb-0 not-selectable fw-bold">RSVP</p>
                    </a>
                </div>
                <div className="mb-3"></div>
            </div>
        </div>
    );
}

export default Invitation;